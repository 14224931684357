import { UUID } from 'crypto'
import { useState } from 'react'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../contexts/AlertContext'
import { UpdateErrorsModel } from '../models/PolicyDetails/UpdateErrorsModel'
import { useBoolean } from './useBoolean'

interface ErrorProcessingModal {
  isModalOpen: boolean
  isCallLoading: boolean
  openModal: (errors: string[]) => void
  closeModal: () => void
  acceptErrors: (entityID: string, entityType: string) => Promise<void>
  rejectErrors: (entityID: string, entityType: string, responseText: string) => Promise<void>
  currentErrorIDs: string[]
}

interface IAlertDetails {
  [key: string]: { [key: string]: { success: string; failure: string } }
}

const AlertDetails: IAlertDetails = {
  policy: {
    accept: {
      success: 'Transaction Edit(s) accepted',
      failure: 'Update was unsuccessful. Please try again.',
    },
    reject: {
      success: 'Transaction Edits(s) rejected and response(s) sent',
      failure: 'Update was unsuccessful. Please try again.',
    },
  },
  unitstat: {
    accept: {
      success: 'Error(s) accepted' as string,
      failure: 'Update was unsuccessful. Please try again.' as string,
    },
    reject: {
      success: 'Error(s) rejected and response(s) sent',
      failure: 'Update was unsuccessful. Please try again.',
    },
  },
}

const useErrorProcessingModal = (
  refetchErrors: () => Promise<void>,
  updateErrors: (request: UpdateErrorsModel) => Promise<number>
): ErrorProcessingModal => {
  const { setDetails } = useAlertContext()
  const [currentErrorIDs, setCurrentErrorIDs] = useState<string[]>([])
  const { value, onTrue, onFalse } = useBoolean()
  const { value: isCallLoading, onTrue: setIsCallLoadingTrue, onFalse: setIsCallLoadingFalse } = useBoolean()

  const openModal = (errors: string[]) => {
    onTrue()
    setCurrentErrorIDs(errors)
  }

  const closeModal = () => {
    onFalse()
    setCurrentErrorIDs([])
  }

  const acceptErrors = async (entityID: string, entityType: string) => {
    closeModal()
    const request: UpdateErrorsModel = {
      editIDs: currentErrorIDs,
      userAccepted: true,
      entityType: entityType,
      entityGuid: entityID as UUID,
    }
    const alert = AlertDetails[entityType].accept
    try {
      setIsCallLoadingTrue()
      await updateErrors(request)
      setDetails({ message: alert.success, severity: AlertSeverity.SUCCESS })
      await refetchErrors()
      // eslint-disable-next-line
    } catch (exception) {
      setDetails({ message: alert.failure, severity: AlertSeverity.ERROR })
    }
    setIsCallLoadingFalse()
  }

  const rejectErrors = async (entityID: string, entityType: string, responseText: string) => {
    closeModal()
    const request: UpdateErrorsModel = {
      editIDs: currentErrorIDs,
      userAccepted: false,
      entityType: entityType,
      entityGuid: entityID as UUID,
      responseText,
    }
    const alert = AlertDetails[entityType].reject
    try {
      setIsCallLoadingTrue()
      await updateErrors(request)
      setDetails({ message: alert.success, severity: AlertSeverity.ERROR })
      // eslint-disable-next-line
    } catch (exception) {
      setDetails({ message: alert.failure, severity: AlertSeverity.ERROR })
    }
    setIsCallLoadingFalse()
  }

  return {
    isModalOpen: value,
    isCallLoading,
    openModal,
    closeModal,
    acceptErrors,
    rejectErrors,
    currentErrorIDs, // exported for testing purposes
  }
}

export default useErrorProcessingModal
